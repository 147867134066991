

































































































































































import {Component} from "vue-property-decorator";
import Vue from "vue";
import {taxonomyFields} from "@/types/consts";
import {vxm} from "@/store";
import {Route} from "vue-router";
import {RouteNext} from "@/types";

@Component
export default class TaxonomyEditPage extends Vue {
  private FIELDS = taxonomyFields;
  private current = Object.assign({}, vxm.main.taxonomyPage.current);

  private loading = vxm.main.loading;

  private updateTaxonomy() {
    vxm.main.updateTaxonomy({
      list: +this.$route.params.list,
      taxonomy: +this.$route.params.node,
      data: this.current
    })
  }


  private beforeRouteEnter(to: Route, from: Route, next: RouteNext) {
    next();
  }

  private beforeRouteUpdate(to: Route, from: Route, next: RouteNext) {
    // console.log('beforeRouteUpdate', to, from);
    this.current = Object.assign({}, vxm.taxonomyStore.taxonomyPage.current);
    next();
  }
}
